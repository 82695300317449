<template>
    <section class="">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :model="model"
                     @reload="handleReload"
                     @edit="handleEdit"
                     :btn_copy = "can_copy"
                     @copy="handleCopy"
                     :btn_add = "can_add"
                     :btn_delete="can_delete"
                     :btn_edit="can_edit"
        >
        </front-table>
        <!--编辑界面-->
        <edit-dialog :edit_mode="edit_mode" :model="model" :single="single" :fields="fields" dual v-if="editable"
                     @success="handleSuccess" @cancel="handleCancel"></edit-dialog>

        <div class="clearfix"></div>
    </section>
</template>

<script>
    import EditDialog from "../../components/edit-mobile-dialog";
    import FrontTable from "../../components/front-mobile-table";
    import FrontMixins from "../../common/mixins/front-mixins";
    import AdminAdmin from "../mixins/admin-admin-mixin"
    export default {
        mixins: [FrontMixins,AdminAdmin],
        components: {FrontTable, EditDialog},
        props:{
            editable:{Boolean, default(){
                    return true;
                }}
        }
    }

</script>

<style lang="scss">

</style>
